


const databits = [
  {
    title: 'Productos certificados',
    content:
      'Nuestros productos químicos se fabrican bajo estrictos estándares de calidad e inocuidad, lo que garantiza su buen desempeño y consistencia lote tras lote. Todos los productos cuentan con notificación/registro sanitario, y cumplen con especificaciones nacionales e internacionales. ',
    
      featureName: 'Productos certificados',
    
    featureImage: '../../images/IconeCertificados.svg'
  },
  {
    title: 'Amigables con el medio Asmbiente',
    content:
      'Nuestros productos son amigables con el medio ambiente. Desde la etapa de desarrollo y formulación, escogemos cuidadosamente materias primas con alto grado de biodegradabilidad y empaques 100% reciclables. Nuestras fórmulas están libres de moléculas bio-acumulativas o nanotecnología cuya interacción con la naturaleza es poco conocida y no se puede controlar.',
    
      featureName: 'Productos amigables con el medio ambiente.',
    
    featureImage: '../../images/IconeMedioAmbiente.svg'
  },
  {
    title: 'Seguridad',
    content:
      'La seguridad de los usuarios es un objetivo principal al momento de formular nuestros productos químicos. Sus ingredientes antimicrobianos de última generación eliminan el 99,9% de gérmenes causantes de enfermedades en 30 seg. *Nuestras fórmulas están libres de productos tóxicos para la salud humana, como son: formol o formaldehido, parabenos, triclosán, entre otros.',
      
    featureName: 'Productos químicos seguros.',
    
    featureImage: '../../images/IconeSeguridad.svg'
  }
  
  
  

];
export default databits;


import React from 'react';
import { Link } from 'gatsby';

import Layout from '../../components/layout/Layout';
import FeatureCard from '../../components/FeatureCard';
import LeadForm from "../../components/LeadForm";
import featuresData from '../../data/productos/higiene-de-manos/features-data';
import { StaticImage } from "gatsby-plugin-image"
import SplitSection from '../../components/SplitSection';
import SplitSectionB2b from '../../components/SplitSectionB2b';
import NewsletterSub from '../../components/NewsletterSub'

const siteVerOpts = [

  {
    label: 'Ecuador',
    value: '',


  },

  {
    label: 'Internacional',
    value: '/',


  },
];

const langOpts = [


  {
    label: 'ES',
    value: '',

  },


  {
    label: 'EN',
    value: `/export/en/products`,

  },


];


const seo={
  title: "Higiene de Manos",
  desc:  "Productos de alta calidad para el lavado y desinfección de manos para uso doméstico e institucional.",
  image: "higiene-de-manos.jpg",
  pathname: "/higiene-de-manos",
}

export default function Named  ({location})  {

  return(
  <Layout location={location} crumbLabel="Higiene de Manos"
  siteVerOpts={siteVerOpts} langOpts={langOpts}
  title={seo.title} desc={seo.desc} banner={seo.image} pathname={seo.pathname}>


<section id="Higiene de manos" className="mt-14">
              <div className="">
               <h1 className="text-5xl font-semibold text-center mb-2">Productos para la higiene de manos</h1>
                <h2 className="text-4xl font-semibold text-center mb-8 text-primary-lighter"> de alta calidad para el lavado y desinfección de manos</h2>
              </div>
            </section>



<section id="productos quimicos descripcion" className="mt-14">
<div className="relative bg-blue-900">
  <div className="h-56 bg-blue-900 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
  <StaticImage src="../../images/higiene-de-manos.jpg"
                      className="flex justify-center "
                      alt="Productos para la higiene y lavado de manos marca dr.clean"
                      placeholder="blurred"
                      layout="constrained"
                       />
  </div>
  <div className="relative max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:py-16 pt-28">
    <div className="md:ml-auto md:w-1/2 md:pl-10">
      <p className=" text-xl text-leftfont-normal text-white pb-6">
      <ul className="list-disc ml-8">
                <li>Productos desarrollados con fórmulas con un poderoso efecto bactericida y viricida. </li>
                <li>Sus ingredientes antimicrobianos de última generación eliminan el 99,9% de gérmenes causantes de enfermedades en 30 segundos.</li>
                <li>Expertos médicos a nivel mundial recomiendan el lavado y desinfección de manos constantes para prevenir el contagio de enfermedades causadas por microrganismos patógenos.</li>
                <li>Idelaes para uso doméstico y profesional.</li>
              </ul></p>
      
    </div>
  </div>
</div>
</section>

    

<section id="categorias productos quimicos" className=" mt-26 bg-gray-200 pt-16 pb-10 ">
  <h3 className="text-primary-default  title-font text-center text-4xl font-semibold pb-2 ">Portafolio de Productos para la Higiene y Desinfección de Manos </h3>
         
    <div className="relative bg-gray-200 px-4 sm:px-6 lg:pt-6 lg:pb-28 lg:px-8">
     <div className="relative max-w-7xl mx-auto mt-10">
      <div className=" max-w-lg mx-auto grid gap-8 lg:grid-cols-4 lg:max-w-none">

        <div className="flex flex-col rounded-lg shadow-lg overflow-hidden ">
          <div className="flex-1 bg-gray-100 p-6 flex flex-col justify-between">
            <div className="flex-1 ">

                <div className="flex flex-wrap mx-auto justify-center ">
                  <h3 className="text-3xl font-semibold text-secondary-lighter text-center leading-relaxed">Jabón Líquido Antibacterial Dr. Clean</h3>
                </div>
              
               <div className="flex flex-wrap mx-auto justify-left">
                 <Link to="/productos/higiene-de-manos/jabon-liquido-con-valvula" className="mt-4 text-primary-lighter inline-flex items-center text-base text-semibold">Jabón líquido antibacterial con válvula
                 <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                 <path d="M5 12h14M12 5l7 7-7 7"></path>
                 </svg>
                 </Link><br/>
                 <Link to="/productos/higiene-de-manos/jabon-liquido-para-relleno" className="mt-2 text-primary-lighter inline-flex items-center text-base text-semibold">Jabón líquido antibacterial con para relleno
                 <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                 <path d="M5 12h14M12 5l7 7-7 7"></path>
                 </svg>
                 </Link><br/>
                 <Link to="/productos/higiene-de-manos/jabon-liquido-en-sachet" className="mt-2 text-primary-lighter inline-flex items-center text-base text-semibold">Jabón líquido antibacterial en sachet para dispensador
                 <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                 <path d="M5 12h14M12 5l7 7-7 7"></path>
                 </svg>
                 </Link><br/>
              </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
        <div className="flex-1 bg-gray-100 p-6 flex flex-col justify-between">
          <div className="flex-1">
              <div className="flex flex-wrap mx-auto justify-center">
                  <h3 className="text-3xl font-semibold text-secondary-lighter text-center leading-relaxed">Gel Antibacterial Dr. Clean</h3>
                </div>
              <div className="flex flex-wrap mx-auto justify-left">
                <Link to="/productos/higiene-de-manos/gel-antibacterial-con-valvula" className="mt-4 text-primary-lighter inline-flex items-center text-base text-semibold">Gel antibacterial con válvula
                 <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                 <path d="M5 12h14M12 5l7 7-7 7"></path>
                 </svg>
               </Link><br/>
               <Link to="/productos/higiene-de-manos/gel-antibacterial-para-relleno" className="mt-2 text-primary-lighter inline-flex items-center text-base text-semibold">Gel antibacterial para relleno
                 <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                 <path d="M5 12h14M12 5l7 7-7 7"></path>
                 </svg>
               </Link><br/>
               <Link to="/productos/higiene-de-manos/gel-antibacterial-en-sachet" className="mt-2 text-primary-lighter inline-flex items-center text-base text-semibold">Gel antibacterial en sachet para dispensador
                 <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                 <path d="M5 12h14M12 5l7 7-7 7"></path>
                 </svg>
               </Link><br/>
              </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
        <div className="flex-1 bg-gray-100 p-6 flex flex-col justify-between">
          <div className="flex-1">
              <div className="flex flex-wrap mx-auto justify-center ">
                  <h3 className="text-3xl font-semibold text-secondary-lighter text-center">Alcohol Antimicrobiano Dr. Clean</h3>
                </div>
              <div className="flex flex-wrap mx-auto justify-left ">
                <Link to="/productos/higiene-de-manos/alcohol-antimicrobiano" className="mt-4 text-primary-lighter inline-flex items-center text-base text-semibold">alcohol antimicrobiano
                 <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                 <path d="M5 12h14M12 5l7 7-7 7"></path>
                 </svg>
               </Link><br/>
              </div>
          </div> 
        </div>
      </div>

     </div>
    </div>
  
  </div>
</section>











<section id="features" className="py-10 md:py-20 ">
      <div className="container mx-auto">
        <div className="w-full bg-fill bg-no-repeat bg-products">
          <h2 className="text-3xl font-semibold text-center bold text-blue-900">¿Por qué elegir nuestros productos para la higiene y desinfección de manos?</h2>
          <p className="md:w-2/3 mx-auto text-center my-5 text-2xl">
          Al elegirnos, usted recibe los siguientes beneficios:
          </p>
        </div>

        <div className="flex flex-wrap justify-center">

            {featuresData.map(feature => (
            <div key={feature.featureName} className="w-full p-4 lg:w-1/3">
              <FeatureCard feature={feature} />
            </div>
            ))}


        </div>
      </div>
</section>

<SplitSectionB2b/>



<LeadForm/>
<NewsletterSub/>

  </Layout>
)}
